import { Grid } from "@mui/material";
import React from "react";
import ReactStoreBadges from "react-store-badges";

export default function AppStoreBadges() {
  return (
    <Grid container spacing={2}>
      <Grid
        xs={12}
        md={6}
        item
        style={{
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
        }}
      >
        <ReactStoreBadges
          platform={"ios"}
          url={
            "https://apps.apple.com/uz/app/chatpods-chat-about-podcasts/id1613388984"
          }
          width={200}
          locale={"en-us"}
          target="_blank"
        />
      </Grid>
      <Grid
        xs={12}
        md={6}
        alignItems="center"
        item
        style={{
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
        }}
      >
        <ReactStoreBadges
          platform={"android"}
          url={"https://play.google.com/store/apps/details?id=com.chatpods.app"}
          width={200}
          locale={"en-us"}
          target="_blank"
        />
      </Grid>
    </Grid>
  );
}
