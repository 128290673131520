import React, { useEffect } from "react";
import * as rdd from "react-device-detect";
import { Helmet } from "react-helmet";
import QRCode from "react-qr-code";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LogoImg from "../images/chatpods-logo-w-text.png";
import AppStoreBadges from "../components/AppStoreBadges";

export default function Links() {
  const { isBrowser, isMobile, isAndroid } = rdd;
  const isIOS = rdd.isIOS || rdd.isMacOs;

  const linkToStore = () => {
    if (isIOS) {
      window.location.href =
        "https://apps.apple.com/uz/app/chatpods-chat-about-podcasts/id1613388984";
    } else if (rdd.isAndroid || rdd.isWindows) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.chatpods.app";
    } else {
      window.location.href =
        "https://apps.apple.com/uz/app/chatpods-chat-about-podcasts/id1613388984";
    }
  };

  const linkToApp = () => {
    if (isMobile) {
      if (deeplink) {
        window.location.href = decodeURIComponent(deeplink);
      }
      if (download) {
        linkToStore();
      }
    }
  };
  const [, , deeplink, data, download] = window?.location?.pathname?.split("/");

  let commentData,
    imgExtension = "png";
  if (data) {
    const decrypted = atob(data);
    commentData = JSON.parse(decrypted);
    console.log("commentData", commentData);
    if (
      commentData.bannerImg?.split(".") &&
      commentData.bannerImg?.split(".").length > 0
    ) {
      imgExtension = commentData.bannerImg?.split(".")[1];
    }
  }

  const { commentTitle, bannerImg, podcastName, fbUname } = commentData;

  // TODO: think about this
  if (typeof window !== "undefined") {
    linkToApp();
  }

  const IsWebComponent = () => {
    return (
      <React.Fragment>
        <Typography
          variant="h5"
          component="h2"
          align="center"
          fontWeight={"bold"}
        >
          1. Scan QR with phone
        </Typography>
        <Typography
          variant="h5"
          component="h2"
          align="center"
          fontWeight={"bold"}
        >
          2. Download app
        </Typography>
        <Typography
          variant="h5"
          component="h2"
          align="center"
          fontWeight={"bold"}
          marginBottom={5}
        >
          3. Click shared link again
        </Typography>
        <div
          alt="Scan QR Code to Download App"
          style={{
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
            margin: "auto",
            height: 200,
          }}
        >
          <QRCode
            size={200}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={window.location.href}
            viewBox={`0 0 200 200`}
          />
        </div>
      </React.Fragment>
    );
  };

  const IsMobileComponent = () => {
    return (
      <React.Fragment>
        <Typography
          variant="h5"
          component="h2"
          align="center"
          fontWeight={"bold"}
        >
          1. Download app
        </Typography>
        <Typography
          variant="h5"
          component="h2"
          align="center"
          fontWeight={"bold"}
          marginBottom={5}
        >
          2. Click shared link again
        </Typography>
        <AppStoreBadges />
      </React.Fragment>
    );
  };

  console.log("commentTitle", commentTitle);
  console.log("podcastName", podcastName);
  console.log("bannerImg", bannerImg);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <meta name="description" content={podcastName} />
        <title>{commentTitle}</title>
        {/* <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={commentTitle} />
        <meta property="og:description" content={podcastName} />
        <meta property="og:url" content="https://chatpods.app/Links" />
        <meta property="og:site_name" content="Chatpods" />
        <meta property="article:publisher" content={fbUname} />
        <meta property="og:image" content={bannerImg} />
        <meta property="og:image:secure_url" content={bannerImg} />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:alt" content={podcastName} />
        <meta property="og:image:type" content={`image/${imgExtension}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={commentTitle} />
        <meta name="twitter:description" content={podcastName} />
        <meta name="twitter:site" content="@chatpods" />
        <meta name="twitter:creator" content="@chatpods" />
        <meta name="twitter:image" content={bannerImg} /> */}
      </Helmet>
      <CssBaseline />
      <Box sx={{ flexGrow: 1 }}>
        <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  height: { xs: "auto", md: 1 },
                  "& img": {
                    objectFit: "cover",
                  },
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                    maxWidth: { xs: 250, md: 350 },
                  }}
                  alt="Chatpods podcast social app logo"
                  src={LogoImg}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                component="h1"
                align="center"
                gutterBottom
              >
                Don't have the Chatpods app yet?
              </Typography>
            </Grid>
          </Grid>
          {isBrowser ? <IsWebComponent /> : <IsMobileComponent />}
        </Container>
      </Box>
    </Box>
  );
}
