import { Routes, Route } from "react-router-dom";
import "./App.css";
import Links from "./components/Links";
import Index from "./components/Index";

function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route exact path="/links/:deeplink/:message" element={<Links />} />
      </Routes>
    </div>
  );
}

export default App;
